
html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecef;
  color: #868ba1;
  /* overflow: hidden; */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
